
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { Head, IlmoContext, TopProgressBar } from "@app/components"
import { withUrql } from "@app/lib"
import { ConfigProvider } from "antd"
import { AppProps } from "next/app"
import { WithUrqlProps } from "next-urql"

import { setLocale } from "../utils/dayjs"

import "../styles/fonts.css"
import "nprogress/nprogress.css"
import "react-color-palette/lib/css/styles.css"

const Ilmo = (props: AppProps & WithUrqlProps) => {
  const { Component, pageProps, resetUrqlClient, router } = props
  const antdLocale = setLocale(router.locale)

  return (
    <IlmoContext.Provider value={{ resetUrqlClient }}>
      <ConfigProvider locale={antdLocale}>
        <TopProgressBar />
        <Head />
        <Component {...pageProps} resetUrqlClient={resetUrqlClient} />
      </ConfigProvider>
    </IlmoContext.Provider>
  )
}

const __Page_Next_Translate__ = withUrql(Ilmo)


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  