module.exports = {
  locales: ["fi", "en", "se"],
  defaultLocale: "fi",
  pages: {
    "*": ["common", "error"],
    "/": ["home", "events"],
    "/login": ["login"],
    "/register": ["register_user"],
    "/reset": ["reset"],
    "/forgot": ["forgot"],
    "/verify": ["verify"],
    "/settings/profile": ["settings"],
    "/settings/accounts": ["settings"],
    "/settings/delete": ["settings"],
    "/settings/emails": ["settings"],
    "/settings/security": ["settings"],
    "/invitations/accept": ["invitations"],
    "/create-event-category": ["events"],
    "/update-registration/[updateToken]": ["register_event"],
    "rgx:^/admin": ["admin", "events", "home", "register_event"],
    "rgx:^/event": ["events", "register_event"],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`./src/translations/${lang}/${ns}.json`).then((m) => m.default),
}
